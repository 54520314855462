import { Payment } from "../Payment/paymentType";

export interface UserTitle {
  selectedValue: string;
  selectedText: string;
  items?: any;
}

export interface ContactPhoneNumber {
  selectedInternationalDiallingCode: string;
  availableInternationalDiallingCodes?: any;
  number: string;
}

export interface DriverViewModel {
  userTitle: UserTitle;
  firstName: string;
  lastName: string;
  emailAddress: string;
  contactPhoneNumber: ContactPhoneNumber;
  alternatePhoneNumber: ContactPhoneNumber;
  isPrimaryDriver: boolean;
  boboId?: number;
  driverDeclarationId?: number;
  savedEnterprisePlusNumber?: string;
  savedEmeraldClubNumber?: string;
  isConsent?: boolean;
}

export const driverViewModelInit = {
  userTitle: { userTitle: "", selectedValue: "", selectedText: "" },
  firstName: "",
  lastName: "",
  emailAddress: "",
  contactPhoneNumber: {
    contactPhoneNumber: "",
    selectedInternationalDiallingCode: "",
    number: "",
  },
  alternatePhoneNumber: {
    contactPhoneNumber: "",
    selectedInternationalDiallingCode: "",
    number: "",
  },
  isPrimaryDriver: true,
  boboId: 0,
  driverDeclarationId: 0,
  savedEnterprisePlusNumber: "",
  savedEmeraldClubNumber: "",
  isConsent: false,
};

export interface ReservationDateTimeViewModel {
  startDateTime: Date;
  endDateTime: Date;
  startTime?: string;
  endTime?: string;
  startDate?: string;
  endDate?: string;
}

export interface AvailableReasonForHire {
  disabled: boolean;
  group: string;
  selected: boolean;
  text: string;
  value: string;
  hasErrors: boolean;
  errors:string[];
}

export interface ReservationReasonForHireViewModel {
  accountUId: string;
  accountName?: string;
  displayName?: string;
  accountNumber?: string;
  CompanyAccountId?: string;
  availableReasonsForHire?: AvailableReasonForHire[];
  isSelectedAccountValid: boolean;
  renderTime?: string;
  reasonForHireLabel?: string;
  brands: string;
  isCreditCard: boolean;
  isPayAtCounter: boolean;
  hasErrors: boolean;
  errors:string[];

}

export interface ReservationLoyaltyViewModel {
  enableLoyalty: boolean;
  loyaltyId?: string;
  loyaltyLastValidValue: string;
  loyaltyPrograms: DropDownList[];
  selectedLoyaltyProgram: string;
  selectedLoyaltyProgramValue: string;
  savedEnterprisePlusNumber: string;
  savedEmeraldClubNumber: string;
}

export interface Fee {
  description: string;
  baseAmount: number;
  totalAmount: number;
  currency: string;
  includedInRate: boolean;
  includedInEstimatedAmount: boolean;
}

export interface Extra {
  id: string;
  description: string;
  totalCost: number;
  unitPrice: number;
  taxAmount: number;
  currency: string;
  amount: number;
  isSelected: boolean;
  isCountable: boolean;
  quantity: number;
  includedInEstimatedAmount: boolean;
  type: string;
  equipmentType: number;
  unitPriceWithCurrency: string;
  totalUnitPriceWithCurrency: string;
  totalPriceWithCurrency: string;
}

export interface ExtrasOptions {
  brand?: string;
  extrasTotalAmount: number;
  extras: Extra[];
}

export interface BrandInfo {
  uniqueKey: string;
  brand: string;
  baseRatePriceWithCurrency: string;
  baseRatePrice: number;
  pricePerBaseWithCurrency: string;
  estimatedTotalAmount: number;
  baseEstimatedTotalAmount: number;
  pricePerBase: number;
  currency: string;
  distance: Distance;
  rate: string;
  isSelected: boolean;
  isSelectedPosition?: boolean;
  unitName: string;
  additionalRentalCharge?: any;
  extrasOptions: ExtrasOptions;
  includedExtras?: Extra[];
  selectedExtras?: Extra[];
}

export interface ClassInfo {
  uniqueKey: string;
  airCondition: boolean;
  manual: boolean;
  passengerQt: number;
  baggageQt: number;
  doorCount?: string;
  category: string;
  size: string;
  pictureUrl: string;
  fuel: string;
  modelCode: string;
  preferred: boolean;
  modelDescription: string;
  fees: Fee[];
  coverages?: any;
  distance: Distance;
  brandInfo: BrandInfo[];
  vehicleClass: string;
}

export interface Distance {
  quantity: number;
  unit: string;
  unlimited: boolean;
}

export interface CarsInformation {
  classInfo: ClassInfo[];
  isDualBrandingCarClass: boolean;
  totalEstimatedCostWithCurrency?: any;
}

export interface CarClass {
  carClassId?: string;
  carClassCode?: string;
  carClass?: string;
  brand?: string;
  carsInformation: CarsInformation;
  availableCarClasses?: any;
  renderTime?: any;
  errors?: any;
  warnings?: any;
  hasErrors: boolean;
  hasWarnings: boolean;
}

export interface ReservationLocationViewModel {
  title: string;
  showControls: boolean;
  nearestBranchIsAirportHasDuplicatedBrand: boolean;
  userSelectedBrand?: string;
  isDeliveryAndCollectionEnabled: boolean;
  selectedMethodTypeId: string;
  methodTypes?: any;
  methodTypesExists: boolean;
  branchDescription: string;
  branchDescriptionWithBranchCode?: string;
  branchLocationSearch?: string;
  peopleSoftId: string;
  stationId: string;
  telephone?: string;
  latitude: string;
  longitude: string;
  country: string;
  brand: string;
  isDeliveryAccepted: boolean;
  isCollectionAccepted: boolean;
  isAirportLocation: boolean;
  airportCity?: string;
  addressLocationSearch: string;
  addressLine1?: any;
  addressLine2: string;
  addressLine3?: any;
  townOrCity: string;
  postCode?: any;
  locationEditorName?: any;
  isDeliveryAddress: boolean;
  divType: string;
}

export interface ReservationLocationsViewModel {
  startLocation: ReservationLocationViewModel;
  endLocation: ReservationLocationViewModel;
}

export interface ReservationNotesViewModel {
  reservationNote?: string;
}

export interface FlightNumberViewModel {
  code?: string;
  flightNumber?: string;
  operatingCompanyCode?: string;
}

export enum ReservationAdditionalInformationFieldTypeCode {
  None = 0,
  Date = 1,
  DropDownList = 2,
  ExactValue = 3,
  Pattern = 4,
  Text = 5,
}
export interface ReservationAdditionalInformationFieldViewModel {
  additionalInformationGid: string;
  sequence: number;
  fieldTypeCode: ReservationAdditionalInformationFieldTypeCode;
  fieldName: string;
  felperText?: string;
  fieldValue: string;
  fieldValueDateString: string;
  dropDownList?: any;
  dropDownListSelectedValue?: string;
  maxLength?: string;
  mandatory: boolean;
  includeInReservation: boolean;
  isBillingRef: boolean;
  customValidate: boolean;
  webApproved: boolean;
}

export interface ReservationAdditionalInformationViewModel {
  additionalInformationFields: ReservationAdditionalInformationFieldViewModel[];
  Message: string;
  RenderTime?: any;
  Errors?: any;
  Warnings?: any;
  HasErrors: boolean;
  HasWarnings: boolean;
}

export interface ReservationNumberViewModel {
  number: string;
}

export interface ReservationBookerViewModel {
  titledFullName: string;
}

export interface JourneyProfilerProductViewModel {
  uId: string;
  companyProductUId: string;
  companyUId: string;
  productId: number;
  productUId: string;
  productName: string;
  productImage: string;
  companyCarShareEnrollmentLink: string;
  companyCarShareResetPasswordLink: string;
  isAvailable: boolean;
  isMidpointEnabled: boolean;
  enableLoyalty: boolean;
  isSelectable: boolean;
  descriptionList: string[];
  isPreffered: boolean;
  notSelectableMessage: string;
  carClubCompanyId: string;
  carClubDriverId: string;
  pin: string;
  displayMidpoints: boolean;
}
export interface ReservationObject {
  companyUId: string;
  boboEnabled: boolean;
  isBOBOToggleEnabled: boolean;
  isaConsentRequiredCountry: boolean;
  driverDeclaration: boolean;
  userPermissions: number;
  journeyViewModel?: any;
  journeyUId: string;
  reservationStatusUId: string;
  startLatitude?: string;
  startLongitude?: string;
  endLatitude?: string;
  endLongitude?: string;
  startLocationPeopleSoftId?: string;
  startLocationStationId?: string;
  endLocationPeopleSoftId?: string;
  endLocationStationId?: string;
  deliveryCollectionEnabled: boolean;
  isAccountValidated: boolean;
  clientCurrentDateTime: Date;
  reservationNumber?: ReservationNumberViewModel;
  bookerViewModel?: ReservationBookerViewModel;
  driverViewModel: DriverViewModel;
  driverViewModelForBOBO: DriverViewModel;
  isBOBOBooking: boolean;
  primaryDriverDeclaration?: DriverDeclaration;
  additionalDriver?: DriverDeclaration[];
  dateTime: ReservationDateTimeViewModel;
  reasonForHire: ReservationReasonForHireViewModel;
  loyalty: ReservationLoyaltyViewModel;
  carClass?: CarClass;
  locations?: ReservationLocationsViewModel;
  locationsURL?: any;
  notesViewModel: ReservationNotesViewModel;
  additionalInformation?: ReservationAdditionalInformationViewModel;
  journeyProduct?: JourneyProfilerProductViewModel;
  isStartDateInPast: boolean;
  isNonEditable: boolean;
  resetJourneyDateTime: boolean;
  isRebuildReservation: boolean;
  showReservationDetails: boolean;
  reservationNumberString: string;
  reservationStatus?: string;
  startDateTimeString: string;
  endDateTimeString: string;
  startLocationString?: string;
  startMethodString: string;
  endLocationString?: string;
  endMethodString: string;
  distance?: string;
  branchAddress?: string;
  startServicingBranch?: any;
  endServicingBranch?: any;
  branchTelephone?: any;
  vehicleClass: string;
  brand: string;
  brandName: string;
  fullBrandName: string;
  driverName: string;
  driverEmail: string;
  accountName?: any;
  displayName?: any;
  accountNumber?: any;
  bookerName: string;
  bookingReferences: string[];
  notes?: any;
  showStartAgain: boolean;
  edtAction: number;
  isInternationalStartLocation: boolean;
  notificationMessage?: string;
  errors?: any;
  warnings?: any;
  hasErrors: boolean;
  hasWarnings: boolean;
  flightNumberViewModel:FlightNumberViewModel;
  isCreditCardToggleEnabled : boolean;
  isCreditCard: boolean;
  payment? : Payment;
  createdDateTimeUtc: string;
}
export interface ReservationDriver {
  userTitle?: UserTitle;
  firstName: string;
  lastName: string;
  emailAddress: string;
  driverDeclarationId: number;
  contactPhoneNumber: ContactPhoneNumber;
  alternatePhoneNumber: ContactPhoneNumber;
  contactPhoneNumberString?: string;
  alternatePhoneNumberString?: string;
  isConsent?: boolean;
}
export interface ValidationResult {
  isInValid: boolean;
  message: string;
}
export interface ValidationResultExtended {
  isInValidCode: boolean;
  messageCode: string;
  isInValidNumber: boolean;
  messageNumber: string;
}
export interface additionalInformationFormikFormError {
  additionalInformationFields: string[];
}

export interface additionalInformationFormikFormTouched {
  additionalInformationFields: boolean[];
}

export interface AdditionalInformationFieldValidationError {
  additionalInformationGid: string;
  errorMessages: string[];
}

export interface AdditionalInformationValidationResponse {
  messages: string[];
  errors: AdditionalInformationFieldValidationError[];
  success: boolean;
}

export const resInitialValue = {
  companyUId: "",
  isBOBOToggleEnabled: false,
  isaConsentRequiredCountry: false,
  boboEnabled: false,
  driverDeclaration: false,
  userPermissions: 0,
  journeyViewModel: {},
  startDate: new Date(),
  endDate: new Date(),
  startTime: new Date(),
  endTime: new Date(),
  journeyUId: "",
  reservationStatusUId: "",
  startLatitude: "",
  startLongitude: "",
  endLatitude: "",
  endLongitude: "",
  startLocationPeopleSoftId: "",
  startLocationStationId: "",
  endLocationPeopleSoftId: "",
  endLocationStationId: "",
  deliveryCollectionEnabled: false,
  createdDateTimeUtc:"",
  isAccountValidated: false,
  clientCurrentDateTime: new Date(),
  reservationNumber: { number: "" },
  bookerViewModel: { titledFullName: "" },
  driverViewModel: {
    userTitle: { userTitle: "", selectedValue: "", selectedText: "" },
    firstName: "",
    lastName: "",
    emailAddress: "",
    contactPhoneNumber: {
      contactPhoneNumber: "",
      selectedInternationalDiallingCode: "",
      number: "",
    },
    alternatePhoneNumber: {
      contactPhoneNumber: "",
      selectedInternationalDiallingCode: "",
      number: "",
    },
    isPrimaryDriver: false,
    savedEnterprisePlusNumber: "",
    savedEmeraldClubNumber: "",
    isConsent: true,
  },
  driverViewModelForBOBO: {
    userTitle: { userTitle: "", selectedValue: "", selectedText: "" },
    firstName: "",
    lastName: "",
    emailAddress: "",
    contactPhoneNumber: {
      contactPhoneNumber: "",
      selectedInternationalDiallingCode: "",
      number: "",
    },
    alternatePhoneNumber: {
      contactPhoneNumber: "",
      selectedInternationalDiallingCode: "",
      number: "",
    },
    isPrimaryDriver: false,
    savedEnterprisePlusNumber: "",
    savedEmeraldClubNumber: "",
    isConsent: true,
  },
  isBOBOBooking: false,
  primaryDriverDeclaration: {
    driverDeclarationDetailId: 0,
    driverDeclarationId: 0,
    companyInternalNumber: 0,
    firstName: "",
    lastName: "",
    emailAddress: "",
    driverDeclarationExpiryDate: new Date(),
    driverDeclarationStatusId: 0,
    driverDeclarationStatus: "",
  } as DriverDeclaration,
  additionalDriver: [
    {
      driverDeclarationDetailId: 0,
      driverDeclarationId: 0,
      companyInternalNumber: 0,
      firstName: "",
      lastName: "",
      emailAddress: "",
      driverDeclarationExpiryDate: new Date(),
      driverDeclarationStatusId: 0,
      driverDeclarationStatus: "",
    },
  ] as DriverDeclaration[],
  dateTime: {
    startDateTime: new Date(),
    endDateTime: new Date(),
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
  },
  reasonForHire: {
    accountUId: "",
    accountName: "",
    displayName: "",
    accountNumber: "",
    availableReasonsForHire: [],
    isSelectedAccountValid: true,
    renderTime: "",
    isCreditCard: false,
    brands: "",
    isPayAtCounter: false,
    hasErrors:false,
    errors:[]
  },
  loyalty: {
    enableLoyalty: false,
    loyaltyId: "",
    loyaltyLastValidValue: "",
    loyaltyPrograms: [
      {
        disabled: false,
        group: {
          disabled: false,
          name: "",
        },
        selected: false,
        text: "",
        value: "",
      },
    ],
    selectedLoyaltyProgram: "",
    selectedLoyaltyProgramValue: "",
    savedEnterprisePlusNumber: "",
    savedEmeraldClubNumber: "",
  },
  locationsURL: "",
  notesViewModel: {},
  flightNumberViewModel: {},
  isCreditCardToggleEnabled:false,
  isCreditCard:false,
  isStartDateInPast: false,
  isNonEditable: false,
  resetJourneyDateTime: false,
  isRebuildReservation: false,
  showReservationDetails: false,
  reservationNumberString: "",
  endDateTimeString: "",
  startDateTimeString: "",
  reservationStatus: "",
  startLocationString: "",
  startMethodString: "",
  endLocationString: "",
  endMethodString: "",
  distance: "",
  branchAddress: "",
  startServicingBranch: "",
  endServicingBranch: "",
  branchTelephone: "",
  vehicleClass: "",
  brand: "",
  brandName: "",
  fullBrandName: "",
  driverName: "demo.etd demo.etd",
  driverEmail: "sonali.v.karde@ehi.com",
  accountNumber: "",
  bookerName: "",
  isInternationalStartLocation: false,
  bookingReferences: [],
  notes: "",
  showStartAgain: true,
  edtAction: 0,
  notificationMessage: "",
  errors: [],
  warnings: [],
  hasErrors: false,
  hasWarnings: false,
};

export const reservationDriverInitData: ReservationDriver = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  driverDeclarationId: 0,
  contactPhoneNumber: {
    availableInternationalDiallingCodes: "",
    selectedInternationalDiallingCode: "",
    number: "",
  },
  alternatePhoneNumber: {
    availableInternationalDiallingCodes: "",
    selectedInternationalDiallingCode: "",
    number: "",
  },
  contactPhoneNumberString: "",
  alternatePhoneNumberString: "",
  isConsent: true,
};

export enum BrandType {
  ENTERPRISE = "ET",
  ALAMO = "AL",
  NATIONAL = "ZL",
  CITER = "CI",
  ATESA = "AT",
}

export enum JourneyMethodType {
  walkIn = "1",
  delivery = "2",
  returnToBranch = "3",
  collection = "4",
  none = "-1",
}

export interface GooglApiLocation {
  location: string;
  peopleSoftId: string;
  stationId: string;
  latitude: string;
  longitude: string;
  brand: string;
  countryCode: string;
  address1: string;
  address2: string;
  address3: string;
  townOrCity: string;
  postcode: string;
  addressSearch: string;
}

export interface JourneyProfilerViewModel {
  activeMileageReimbursement: boolean;
  activeTravelPolicy: boolean;
  additionalInformation: string;
  allPoints: ViaPoints[];
  availableModesOfTransport: DropDownList[];
  bookingReferences: string[];
  clientCurrentDateTime: string;
  disableEditJourney: boolean;
  edtAction: number;
  endDateTime: string;
  endLatitude: string;
  endLocation: ReservationLocationViewModel;
  endLocationAddressJson: string;
  endLongitude: string;
  endPeopleSoftId: string;
  endStationId: string;
  isDailyRentalSingleProduct: boolean;
  isInternationalStartLocation: boolean;
  isJourneyProfile: boolean;
  isMidpointsEnabled: boolean;
  isMidpointsEnabledInAdminSide: boolean;
  isRebook: boolean;
  journeyProfilerUId: string;
  modeOfTransportUId: string;
  originalJourneyProfilerUId: string;
  referenceNumber: string;
  registrationNumber: string;
  reservationCreated: boolean;
  riskAssessmentExists: boolean;
  startDateTime: string;
  startLocation: ReservationLocationViewModel;
  startLocationAddressJson: string;
  useOwnVehicle: boolean;
  useStartAsEndLocation: boolean;
  viaPoints: ViaPoints[];
}

interface ViaPoints {
  newClientIndex: number;
  destination: string;
  peopleSoftId: string;
  stationId: string;
  latitude: string;
  longitude: string;
  travelDistance: number;
  travelDistanceDurationString: string;
  travelDuration: number;
  order: number;
  uId: string;
  locationPlaceholder: string;
}
export interface DropDownList {
  disabled: boolean;
  group: {
    disabled: boolean;
    name: string;
  };
  selected: boolean;
  text: string;
  value: string;
}

export interface ExtraEditingProps {
  brandInfo: BrandInfo;
  includedExtras?: Extra[];
  selectedExtras?: Extra[];
  modelCode?: string;
  modelDescription?: string;
  updateTotal(): void;
  updateExtras(extras: Extra[], key: string): void;
  mainIndex: string;
}

export interface ReservationLocInfoViewModel {
  Name: string;
  Latitude: string;
  Longitude: string;
  StationIds: StationPeopleId[];
  PeopleSoftIds: StationPeopleId[];
}

export interface StationPeopleId {
  brand: string;
  value: string;
}

export interface DriverDeclaration {
  driverDeclarationDetailId: number;
  driverDeclarationId?: number;
  companyInternalNumber: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  contactPhoneNumber?: ContactPhoneNumber;
  alternatePhoneNumber?: ContactPhoneNumber;
  driverDeclarationExpiryDate: Date;
  driverDeclarationStatusId?: number;
  driverDeclarationStatus: string;
  boboId?: number;
}

export interface AdditionalDriverViewModel {
  primaryDriverDeclarationId: number;
  reservationEndDate?: string;
  additionalDriver?: DriverDeclaration[];
}

export enum LoyaltyProgramType {
  pleaseSelect = "00000000-0000-0000-0000-000000000000",
  ET = "09f61760-9c1b-424e-a229-b987ed5d50ec",
  ZL = "83020d98-3ebc-4caa-9add-abb4b3cdff2d",
}
