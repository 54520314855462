import React, { useState, useEffect } from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FocusError from "../../../controls/formik/FocusOnError";
import { useStyles } from "./bulkuploadStyles";
import AttachFileIconcon from "@mui/icons-material/AttachFile";
import {
  csvJSON,
  setNullValuesToEmpty,
  hasValidColumns,
  validateData,
  transformData,
} from "./bulkuploadHelpers";
import { Formik, Form, ErrorMessage } from "formik";

import { useTranslation } from "react-i18next";
import { BulkUploadDetail, BulkUploadInitialValue } from "../usertype";
import { bulkUploadValidationSchema } from "../userValidationSchema";
import { useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  Backdrop,
  Button,
  Grid,
  Typography,
  Select,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  showSuccessAlertMessage,
  showErrorAlertMessage,
} from "../../store/actions/shared/alertActions";
import { broadcastAnnouncement } from "../../store/actions/shared/announcementActions";
import { useDispatch } from "react-redux";

import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";

export default function BulkUploadUser() {

  interface CompanyItem
{
  disabled: boolean;
  group: null;
  selected: boolean;
  text: string;
  value: string;
}

const initialCompanyItem: CompanyItem =
{
  disabled: false,
  group: null,
  selected: false,
  text: "Please Select",
  value: "",
}

const [companyList, setCompanyList] = useState<CompanyItem[]>([]);
const [selectedCompany, setSelectedCompany] = useState<CompanyItem>(initialCompanyItem);

  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState<BulkUploadDetail>(
    BulkUploadInitialValue
  );
  const [file, setFile] = useState<any | null>();
  const [data, setData] = useState<any[]>([]);
  const [logMessage, setLogMessage] = useState<string[]>([]);
  const fileReader = new FileReader();
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const formikRef = React.useRef<any>(null);

  let history = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const requriedHeaders = [
    "username",
    "firstname",
    "lastname",
    "email",
    "requiredaction",
  ];

  const getBulkUploadDetail = async () => {
    setLoading(true);
    await axiosInstance
      .get("/identity/bulkupload")
      .then((res) => {
        setInitialData(setNullValuesToEmpty(res.data));
        console.log("Data Received: ", res.data);
        let companyList = res.data.companyList?.items?.map(item => {
          let list: CompanyItem = {
          "disabled":item.disabled,
          "group":item.group,
          "selected": item.selected,
          "text" :item.text,
          "value": item.value,
        }
          return list
      })    
  
      setCompanyList(companyList?? []);

      let selectedCompany = companyList[0];
      setSelectedCompany(selectedCompany);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data.message) {
          const errorlist = err.response.data.message;
          dispatch(showErrorAlertMessage(errorlist));
          dispatch(broadcastAnnouncement(errorlist));
        } else {
          dispatch(
            showErrorAlertMessage(
              "An error has occured, please contact support team"
            )
          );
          dispatch(
            broadcastAnnouncement(
              "An error has occured, please contact support team"
            )
          );
        }
        appInsights.trackException({
          exception: err,
          properties: { method: "getNewUserDetail", Component: "AddUser" },
          severityLevel: SeverityLevel.Error,
        });
      });
  };

  const handleCancel = () => {
    history("/admin/users");
  };

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([logMessage.join("\r\n")], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    let date = new Date();
    element.download = `log_${date.valueOf()}.txt`;
    document.body.appendChild(element);
    element.click();
  };

  const downloadInputTemplateFile = () => {
    const element = document.createElement("a");
    //  const file = new Blob([JSON.stringify(jsonStream)], {
    //   type: "text/plain",
    let templateStr =
      "Title,UserName,UpdateUserName,FirstName,LastName,Email,UpdateEmail,CountryCode,PreferredPhone,AlternatePhone,LineManagerEmail,RequiredAction,BookOnBehalf,ViewOnly,EnterprisePlusNumber,EmeraldClubNumber\r\n";
    const file = new Blob([templateStr], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `ETD_BulkUpload_Template.csv`;
    document.body.appendChild(element);
    element.click();
  };

  //const headerKeys = Object.keys(Object.assign({}, ...data));

  const handleOnChange = (e) => {
    setFile(null);
    setLogMessage([]);
    setFile(e.target.files[0]);
  };
  const handleClick = (e) => {
    if (null !== hiddenFileInput.current) {
      setFile(null);
      hiddenFileInput.current.value = "";
    }
    if (formikRef.current) {
      formikRef.current.setFieldValue("isFileValid", false);
    }
  };
  const handleUpload = () => {
    let errormsg: string[] = [];
    try {
      if (file) {
        if (file.name.toLowerCase().endsWith(".csv")) {
          fileReader.onload = function (event) {
            const csvOutput = event.target?.result;
            let dataReceived = csvJSON(csvOutput);

            const headerReceived = Object.keys(
              Object.assign({}, ...dataReceived)
            );
            if (!hasValidColumns(requriedHeaders, headerReceived)) {
              setLogMessage((current) => [
                ...current,
                "Invalid File. Some mandatory columns missing (firstname, lastname, username, email, requiredAction)",
              ]);
              return;
            } else {
              const [isValid, errmsg] = validateData(dataReceived);
              console.log("IsValid: ", isValid, errmsg);
              if (isValid) {
                setData(dataReceived);
                setLogMessage((current) => [
                  ...current,
                  ...[
                    `File is valid.`,
                    `Total records to be imported : ${dataReceived.length}`,
                  ],
                ]);
                if (formikRef.current) {
                  formikRef.current.setFieldValue("isFileValid", true);
                }
              } else {
                setLogMessage((current) => [...current, ...errmsg]);
                if (formikRef.current) {
                  formikRef.current.setFieldValue("isFileValid", false);
                }
              }
            }
          };
          fileReader.readAsText(file);
        } else {
          errormsg.push("Invalid File Format. Please select a 'csv' file");
        }
      }
    } catch (err) {
      errormsg.push("ERROR in reading the uploaded file");
    }
    setLogMessage((current) => [...current, ...errormsg]);
  };

  useEffect(() => {
    document.title =
      "Enterprise Rent-A-Car - " + t("Users.bulkuploadPageTitle");
    getBulkUploadDetail();
  }, []);

  useEffect(() => {
    console.log("File Change called: ", file);
    handleUpload();
  }, [file]);

  return (
    <Box data-testid="bulkUploadUserBox" id="bulkUploadUserBox" boxShadow={3} p={3} bgcolor="background.paper">
      <Formik
        initialValues={loading ? BulkUploadInitialValue : initialData}
        validateOnChange={true}
        validateOnBlur={false}
        validationSchema={bulkUploadValidationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
        onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
          var isValid = validateData(data);
          console.log(data);
          if (isValid) {
            console.log(
              "Selected Language: ",
              values.languageList?.selectedValue,
              values.languageList?.selectedText
            );

            var model = {
              CompanyUId: values.companyList?.selectedValue,
              LanguageId: values.languageList?.selectedValue,
              Users: transformData(
                data,
                values.languageList?.selectedValue,
                values.languageList?.selectedText
              ),
            };

            console.log("model: ", model);
            await axiosInstance
              .post("/identity/bulkupload", model)
              .then((res) => {
                setSubmitting(false);

                let responseResult = res.data;
                console.log("Result ", responseResult.data);
                let msgList: string[] = [];
                if (responseResult.success) {
                  msgList.push(
                    `Users Added : ${responseResult.data.added.users.length.toString()}.`
                  );
                  msgList.push(
                    `Users Updated : ${responseResult.data.updated.users.length.toString()}.`
                  );
                  msgList.push(
                    `Users Deleted : ${responseResult.data.deleted.users.length.toString()}.`
                  );
                  //process successfully added
                  /*    if (responseResult.data.added.users.length > 0) {
                    msgList.push("Successfull added users");
                    for (
                      let i = 0;
                      i < responseResult.data.added.users.length;
                      i++
                    ) {
                      const rec = responseResult.data.added.users[i];
                      msgList.push(
                        `LineNo= ${rec.lineNo.toString()}, Username= ${
                          rec.username
                        }, successfully added`
                      );
                    }
                  }
                  //process successfully updated
                  if (responseResult.data.updated.users.length > 0) {
                    msgList.push("Successfull updated users");
                    for (
                      let i = 0;
                      i < responseResult.data.updated.users.length;
                      i++
                    ) {
                      const rec = responseResult.data.updated.users[i];
                      msgList.push(
                        `LineNo= ${rec.lineNo.toString()}, Username= ${
                          rec.username
                        }, successfully updated`
                      );
                    }
                  }
                  //process successfully deleted
                  if (responseResult.data.deleted.users.length > 0) {
                    msgList.push("Successfull deleted users");
                    for (
                      let i = 0;
                      i < responseResult.data.deleted.users.length;
                      i++
                    ) {
                      const rec = responseResult.data.deleted.users[i];
                      msgList.push(
                        `LineNo= ${rec.lineNo.toString()}, Username= ${
                          rec.username
                        }, successfully deleted`
                      );
                    }
                  }
                  */
                  //process errors
                  if (responseResult.data.errors.length > 0) {
                    for (
                      let i = 0;
                      i < responseResult.data.errors.length;
                      i++
                    ) {
                      msgList.push(
                        responseResult.data.errors[i].errorsMessages[0]
                      );
                    }
                  }
                  setLogMessage(msgList);
                }
                //dispatch(showSuccessAlertMessage(t("Users.saveUserSuccess")));
                //dispatch(broadcastAnnouncement(t("Users.saveUserSuccess")));
              })
              .catch((err) => {
                console.log("ERROR:", err);
                setSubmitting(false);
                if (err.response && err.response.data.messages) {
                  let errorlist = err.response.data.messages.filter(
                    (n) => n !== ""
                  );
                  const translatedErrorlist = errorlist.map(function (value) {
                    return t(value);
                  });
                  //.join("\r\n");
                  dispatch(showErrorAlertMessage(translatedErrorlist));
                  dispatch(broadcastAnnouncement(translatedErrorlist));
                } else {
                  dispatch(
                    showErrorAlertMessage("unable to process bulk upload.")
                  );
                  dispatch(
                    broadcastAnnouncement("unable to process bulk upload.")
                  );
                }

                appInsights.trackException({
                  exception: err,
                  properties: { method: "onSubmit", Component: "AddUser" },
                  severityLevel: SeverityLevel.Error,
                });
              });
          }
          handleClick(null);
          window.scrollTo(0, 0);
        }}
      >
        {({
          values,
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          touched,
          handleChange,
          errors,
          isSubmitting,
        }) => (
          <Grid container>
            <Backdrop
              className={classes.backdrop}
              open={isSubmitting || loading}
            >
              <CircularProgress />
            </Backdrop>
            <Grid item xs={12} md={12}>
              <Typography id="titleHeader" variant="h1">
                {t("Users.bulkuploadPageTitle")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              
                <br />
                <Typography id="lblisntructions" variant="body2">
                Please note that the following:-
                </Typography>
                <ul>
                  <li>
                  <Typography id="lblisntructions" variant="body2">
                    The file must be in .csv format.
                    </Typography>
                    </li>
                  <li>
                  <Typography id="lblisntructions" variant="body2">
                    The maximum limit for number of users to be imported at one
                    time is 200.
                    </Typography>
                  </li>
                  <li>
                  <Typography id="lblisntructions" variant="body2">
                    Please download the sample Bulk Upload Template file.
                    </Typography>
                    <AttachFileIconcon onClick={downloadInputTemplateFile} />
                    <Typography id="lblisntructions" variant="body2">
                    ETD_BulkUpload_Template.csv
                    </Typography>
                  </li>
                  <li>
                  <Typography id="lblisntructions" variant="body2">
                    The <b>RequiredAction</b> column can only have one of the
                    following values (CREATE, UPDATE, DELETE).
                  </Typography>
                  </li>
                </ul>
              
            </Grid>
            <Grid
              container
              direction="row"
              style={{ marginTop: "1em" }}
              className={classes.root}
            >
              <Form style={{ width: "100%" }}>
                <Grid container>
                  <Grid    
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <FormControl variant="outlined" fullWidth>
                      <Typography style={{ fontWeight: "bold"}}>
                        {t("Users.company")}
                      </Typography>

                      {/* <Select
                        native
                        value={values.companyList?.selectedValue}
                        onChange={(opt) => {
                          let text = values.companyList?.items?.find(
                            (x) => x.value === opt.target.value
                          )?.text;
                          setFieldValue("companyList.selectedText", text);
                          setFieldValue(
                            "companyList.selectedValue",
                            opt.target.value
                          );
                          setFieldTouched("companyList");
                        }}
                        fullWidth
                        inputProps={{
                          id: "companyList.selectedValue",
                          name: "companyList.selectedValue",
                          title: "select a company",
                          "aria-label": t("Users.company"),
                          "data-selectedtext": values.companyList?.selectedText,
                        }}
                        name="companyList.selectedValue"
                        id="companyList.selectedValue"
                        error={
                          errors["companyList"] !== undefined ? true : false
                        }
                        aria-describedby={
                          errors.companyList && touched.companyList
                            ? "companyName-helper-select"
                            : undefined
                        }
                      >
                        {values.companyList?.items?.map((opt: any) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>  */}
                         <Autocomplete
                          fullWidth
                          //disabled={authService.isCompanyAdmin()}
                          id="companyList.selectedValue"
                          aria-label={t("Users.company")}
                          options={companyList}
                          autoComplete
                          value={selectedCompany}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id={"companyList.selectedValue"}
                              name={"companyList.selectedValue"}
                              title={"select a company"}
                              data-selectedtext={values.companyList?.selectedText}
                              //label={t("Users.company")}
                              variant="outlined"

                              error={
                                errors["companyList"] !== undefined ? true : false
                              }
                              aria-describedby={
                                errors.companyList && touched.companyList
                                  ? "companyName-helper-select"
                                  : undefined
                              }
                            />
                          )}
                          getOptionLabel={(option: CompanyItem) => option.text}
                          // isOptionEqualToValue={(option, value) =>
                          //   option.value === value.value
                          // }
                          // getOptionDisabled={(option) =>
                          //   option === companyList[0]
                          // }
                          onChange={async (event, value) => {
                            if (value == null) return;

                            let selectedCompany = companyList?.find(
                              (obj) => obj.value === value.value
                            );
                            if (selectedCompany !== undefined) {
                              setSelectedCompany(selectedCompany);
                            }        
                            setFieldValue(
                              "companyList.selectedValue",
                              value.value
                            );

                            setFieldTouched("companyList");
                            // await updateDriverId(value.value, setFieldValue);
                            // getCompanyProfile(value.value);
                          }}
                          onBlur={() => {
                            setFieldTouched("companyList");
                          }}
                        />                     
                      <ErrorMessage name="companyList">
                        {(msg) => (
                          <span
                            id="companyName-helper-select"
                            className={classes.errorWarning}
                          >
                            {t(msg)}
                          </span>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <FormControl variant="outlined" fullWidth>
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Users.preferredLanguage")}
                      </Typography>

                      <Select
                        native
                        value={values.languageList?.selectedValue}
                        onChange={(opt) => {
                          let text = values.languageList?.items?.find(
                            (x) => x.value === opt.target.value
                          )?.text;
                          setFieldValue("languageList.selectedText", text);
                          setFieldValue(
                            "languageList.selectedValue",
                            opt.target.value
                          );
                          setFieldTouched("languageList");
                        }}
                        fullWidth
                        inputProps={{
                          id: "languageList.selectedValue",
                          name: "languageList.selectedValue",
                          title: "select a language",
                          "aria-label": t("Users.company"),
                          "data-selectedtext":
                            values.languageList?.selectedText,
                        }}
                        name="languageList.selectedValue"
                        id="languageList.selectedValue"
                        error={
                          errors["languageList"] !== undefined ? true : false
                        }
                        aria-describedby={
                          errors.languageList && touched.languageList
                            ? "languageList-helper-select"
                            : undefined
                        }
                      >
                        {values.languageList?.items?.map((opt: any) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                      <ErrorMessage name="languageList">
                        {(msg) => (
                          <span
                            id="companyName-helper-select"
                            className={classes.errorWarning}
                          >
                            {t(msg)}
                          </span>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <FormControl variant="outlined" fullWidth>
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Users.chooseFile")}
                      </Typography>
                      <input
                        type={"file"}
                        accept={".csv"}
                        ref={hiddenFileInput}
                        onClick={handleClick}
                        onChange={handleOnChange}
                        name="isFileValid"
                        id="isFileValid"
                        data-testid="testFileUpload"
                        aria-label={t("Users.chooseFile")}
                      />
                      <ErrorMessage name="isFileValid">
                        {(msg) => (
                          <span
                            id="fileUploaded-helper-select"
                            className={classes.errorWarning}
                          >
                            {t(msg)}
                          </span>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <TextareaAutosize
                      data-testid="textAreaId"
                      maxRows={10}
                      minRows={5}
                      className={classes.textarea}
                      aria-label="maximum height"
                      placeholder="Bulk Upload Status..."
                      readOnly={true}
                      defaultValue={logMessage.join("\r\n")}
                    />
                    <Button
                      color="primary"
                      id="btnDownload"
                      variant="contained"
                      type="button"
                      onClick={downloadTxtFile}
                      style={{ marginLeft: "0.5em", textTransform: "none" }}
                    >
                      {t("Users.btnDownload")}
                    </Button>
                  </Grid>

                  <Grid container justifyContent="flex-end">
                    <Button
                      id="btnSubmit"
                      color="primary"
                      variant="contained"
                      disabled={!isValid || !dirty}
                      type="submit"
                      style={{ textTransform: "none" }}
                    >
                      {t("Users.btnUpload")}
                    </Button>
                    <Button
                      classes={{ outlinedPrimary: classes.outlinedButton }}
                      id="btnCancel"
                      color="primary"
                      variant="outlined"
                      type="button"
                      onClick={handleCancel}
                      style={{ marginLeft: "0.5em", textTransform: "none" }}
                    >
                      {t("Users.btnCancel")}
                    </Button>
                  </Grid>
                </Grid>
                <FocusError />
              </Form>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Box>
  );
}
