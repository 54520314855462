import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { Stepper, Step, StepLabel } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import useStyles from "./reservationStyles";
import { useTranslation } from "react-i18next";
import { ReservationSteps, StepIds } from "./reservationType";
import RiskAssessmentForm from "./steps/RiskAssessment/riskAssessmentForm";
import ReservationForm from "./steps/Reservation/reservationForm";
import LocationForm from "./steps/Reservation/locationForm";
import PaymentForm from "./steps/Payment/paymentForm";
import DriverDetailsForm from "./steps/DriverDetails/driverDetailsForm";
import DRVehicleSelectionForm from "./steps/DRVehicles/drVehicleSelectionForm";
import ReservationDetailsForm from "./steps/DriverDetails/reservationDetailsForm";
import ProductSelectionForm from "./steps/Products/productSelectionForm";
import CarClubSelectionForm from "./steps/CarClub/carClubSelection";
import AdditionalInformationForm from "./steps/AdditionalInformation/additionalInformationForm";
import { ReservationAdditionalInformationViewModel } from "./steps/Reservation/reservationFormType";
import ConfirmReservationForm from "./steps/Confirm/confirmReservationForm";
import ConfirmCarClubForm from "./steps/Confirm/confirmCarClubForm";
import ReviewReservationForm from "./steps/Review/reviewReservationForm";
import ReviewCarClubForm from "./steps/Review/reviewCarClubForm";
import ReviewSpecialityRequest from "./steps/Review/reviewSpecialityRequest";
import Divider from "@mui/material/Divider";
import { updateJourneyProfileDetails } from "components/store/slicers/journeyInputSlice";
import { useLocation } from "react-router-dom";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import JourneyDetailsForm from "../Reservation/steps/Shared/journeyDetailsForm";
import { useNavigate } from "react-router-dom";
import {
  JourneyProfilerProductViewModel,
  JourneyType,
} from "views/reservation/ReservationViewType";
import {
  CarClubAutoVeraDetails,
  JourneyProfile,
  JourneyProfilerViaPoint,
} from "./steps/Shared/journeyProfileType";
import axiosInstance from "helpers/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAlertMessage,
  showErrorAlertMessage,
  showInfoAlertMessage,
} from "components/store/actions/shared/alertActions";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import CarClubDetail from "./steps/Driver/carClubDetail";
import { carClubVehicleAvailability } from "components/store/actions/reservation/carclubActions";
import ReservationRequest from "./steps/SpecialityRequest/reservationRequest";
import { updateSpecialityRequest } from "components/store/actions/reservation/specialityActions";
import {
  SpecialityRequest,
  SpecialityRequestInitialValue,
  SpecialityRequestState,
} from "./steps/SpecialityRequest/specialityRequestType";
import { useSpecialityRequest } from "components/store/reducers/reservation/specialitySelector";
import { CreateSpecialityRequest } from "services/SpecialityService";
import { DriverDeclaration } from "./steps/JourneyStart/journeyInputType";
import { authService } from "services/authService";
import { isNullOrUndefined } from "helpers/stringHelpers";
import { AppState } from "components/store/reducers/rootReducer";

const CompletedIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm-2 17l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z"
        fill={props.bgColor}
      />
    </svg>
  );
};
const StepIcon = (props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
        borderRadius: 12,
        backgroundColor: props.bgColor,
      }}
    >
      <span style={{ color: "#fff" }}>{props.id}</span>
    </div>
  );
};
export default function ReservationWizard() {
  const classes = useStyles();
  const [currentSteps, setCurrentSteps] = useState(new Set([0]));
  const [loading, setLoading] = React.useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [journeyId, setjourneyId] = useState("");
  const [mode, setMode] = useState("0");
  const [origJourneyId, setorigJourneyId] = useState("");
  const [hideCCTile, setHideCCTile] = React.useState(false);
  const [focusCCPwd, setfocusCCPwd] = React.useState(false);
  const { companyInfo } = useSelector(
    (state: AppState) => state.companyInfo || {}
  );
  const { t } = useTranslation();
  //const steps: string[] = getSteps();
  const theme = useTheme();
  const isSmXs = useMediaQuery(theme.breakpoints.down("lg"));
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const qId = queryParams.get("id");
  const qOid = queryParams.get("oid");
  const qMode = queryParams.get("mode");
  const qType = queryParams.get("type");
  const [counter, setCounter] = useState<number>(0);

  const history = useNavigate();
  const dispatch = useDispatch();
  const milageReimbSteps = new Set([0, 1, 2]);
  const milageReimbWithAddFieldsSteps = new Set([0, 1, 2, 3]);
  const dailyRentalSteps = new Set([0, 2, 14, 4, 15, 6, 7]);
  const dailyRentalCreditCardSteps = new Set([0, 2, 14, 4, 17, 15, 6, 7]);
  const bbcDailyRentalSteps = new Set([0, 2, 16, 11, 6, 7]);
  const bbcDirectDailyRentalSteps = new Set([0, 16, 11, 6, 7]);
  const directDailyRentalSteps = new Set([0, 14, 4, 15, 6, 7]);
  const directDailyRentalCreditCardSteps = new Set([0, 14, 4, 17, 15, 6, 7]);
  const carClubStepsWithoutDriver = new Set([0, 2, 5, 8, 9]);
  const carClubSteps = new Set([0, 2, 10, 5, 8, 9]);
  const specialityRequestSteps = new Set([0, 12, 13]);
  const [productData, setProductData] = React.useState<
    JourneyProfilerProductViewModel[]
  >([]);
  const [journeyProfile, setJourneyProfile] =
    React.useState<JourneyProfile | null>(null);
  const [additionalFields, setAdditionalFields] =
    React.useState<ReservationAdditionalInformationViewModel | null>(null);
  const [hasAdditionalFields, setHasAdditionalFields] = React.useState(false);
  const [journeyProfilerViaPoints, setJourneyProfilerViaPoints] =
    React.useState<JourneyProfilerViaPoint[]>([] as JourneyProfilerViaPoint[]);
  const specialityRequest: SpecialityRequest =
    useSpecialityRequest() ?? SpecialityRequestInitialValue;
  const [svRequest, setSvRequest] = React.useState<SpecialityRequest>(
    SpecialityRequestInitialValue
  );
  var carClubSelectedDriver = "";
  const isCCSharedAccessEnabled = productData?.find(
    (i) => i.productUId.toUpperCase() === JourneyType.carClub
  )?.isSharedAccessEnabled;
  const handleCarClubAutoVeraDetails = (
    autoVeraDetails: CarClubAutoVeraDetails
  ) => {
    authService.setCarClubAutoVeraInfo(autoVeraDetails);
  };
  React.useEffect(() => {
    const fetchData = async () => {
      setjourneyId(qId ?? "");
      setorigJourneyId(qOid ?? "");
      setMode(qMode ?? "");
  
      try {
        GetWizardData();
      } catch (error) {
        console.error("Error GetWizardData:", error);
      }
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    setSvRequest(specialityRequest);
  }, [specialityRequest]);

  React.useEffect(() => {
    console.log("Changing page from resWiz", activeStep);

    const userInfo = authService.currentUserValue;
    if (!userInfo) {
      console.log("User information is not available");
      return;
    }

    try {
      if ((window as any)?.pendo?.isReady()) {
        if (counter === 0) {
          console.log("User has enabled Pendo - setting Pendo info");
          (window as any).pendo.identify({
            account: {
              id: userInfo.companyUId,
              name: userInfo.companyName,
            },
            visitor: {
              accountid: userInfo.companyUId,
              applocale: userInfo.isoLanguageCode,
              country: userInfo.companyCountryCode,
              id: userInfo.b2cobjectId,
              role: userInfo.role,
            },
          });
          setCounter(1);
        } else {
          console.log("Pendo was already enabled the first time");
        }
      } else {
        console.log("Pendo is not ready");
        setCounter(0);
      }
    } catch (error) {
      console.error("Error setting Pendo info:", error);
    }
  }, [activeStep]);

  function _renderStepContent(
    step,
    id,
    origid,
    updateParent,
    handleBack,
    handleNext
  ) {
    const steps: string[] = getSteps();
    let stp: ReservationSteps = ReservationSteps[steps[step]];

    switch (stp) {
      case ReservationSteps.JOURNEY:
        return <div>Not Found</div>;
      case ReservationSteps.RISKASSESSMENT:
        document.title =
          t("basePageTitle") + t("ReservationWizard.riskAssessmentPageTitle");
        return (
          <RiskAssessmentForm
            step={step}
            journeyUId={id}
            origJourneyUId={origid}
            updateParent={updateParent}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case ReservationSteps.PRODUCTS:
        document.title =
          t("basePageTitle") + t("ReservationWizard.productSelectionPageTitle");
        return (
          <ProductSelectionForm
            step={step}
            journeyUId={id}
            journeyDetails={journeyProfile}
            origJourneyUId={origid}
            addFieldsEnabled={hasAdditionalFields}
            products={productData}
            updateParent={updateParent}
            handleBack={handleBack}
            handleNext={handleNext}
            hideCCTile={hideCCTile}
            focusCCPwd={focusCCPwd}
          />
        );
      case ReservationSteps.ADDITIONALINFO:
        document.title =
          t("basePageTitle") + t("ReservationWizard.additionalInfoPageTitle");
        return (
          <AdditionalInformationForm
            step={step}
            journeyUId={id}
            origJourneyUId={origid}
            updateParent={updateParent}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case ReservationSteps.RESERVATION:
        document.title =
          t("basePageTitle") + t("ReservationWizard.reservationPageTitle");
        return (
          <LocationForm
            step={step}
            journeyUId={id}
            origJourneyUId={origid}
            journeyDetails={journeyProfile}
            updateParent={updateParent}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );

      case ReservationSteps.BBC_RESERVATION:
        document.title =
          t("basePageTitle") + t("ReservationWizard.reservationPageTitle");
        return (
          <ReservationForm
            step={step}
            journeyUId={id}
            origJourneyUId={origid}
            journeyDetails={journeyProfile}
            updateParent={updateParent}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case ReservationSteps.VEHICLES:
        document.title =
          t("basePageTitle") + t("ReservationWizard.vehicleSelectionPageTitle");
        return (
          <CarClubSelectionForm
            step={step}
            journeyUId={id}
            origJourneyUId={origid}
            journeyDetails={journeyProfile}
            updateParent={updateParent}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case ReservationSteps.DR_DRIVERS:
        document.title =
          t("basePageTitle") +
          t("ReservationWizard.reservationDetailsPageTitle");
        return (
          <DriverDetailsForm
            step={step}
            journeyUId={id}
            origJourneyUId={origid}
            journeyDetails={journeyProfile}
            updateParent={updateParent}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case ReservationSteps.PAYMENT:
        document.title =
          t("basePageTitle") + t("ReservationWizard.paymentPageTitle");
        return (
          <PaymentForm
            step={step}
            journeyUId={id}
            origJourneyUId={origid}
            journeyDetails={journeyProfile}
            updateParent={updateParent}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case ReservationSteps.DR_VEHICLES:
        document.title =
          t("basePageTitle") + t("ReservationWizard.reservationPageTitle");
        return (
          <DRVehicleSelectionForm
            step={step}
            journeyUId={id}
            origJourneyUId={origid}
            journeyDetails={journeyProfile}
            updateParent={updateParent}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );

      case ReservationSteps.DETAILS:
        document.title =
          t("basePageTitle") +
          t("ReservationWizard.reservationDetailsPageTitle");
        return (
          <ReservationDetailsForm
            step={step}
            journeyUId={id}
            origJourneyUId={origid}
            journeyDetails={journeyProfile}
            //reservationProfileDetails={journeyProfile}
            updateParent={updateParent}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case ReservationSteps.REVIEW:
        document.title =
          t("basePageTitle") + t("ReservationWizard.reviewPageTitle");
        return (
          <ReviewReservationForm
            step={step}
            journeyUId={id}
            origJourneyUId={origid}
            updateParent={updateParent}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );

      case ReservationSteps.CONFIRM:
        document.title =
          t("basePageTitle") + t("ReservationWizard.confirmationPageTitle");
        return (
          <ConfirmReservationForm
            step={step}
            journeyUId={id}
            origJourneyUId={origid}
            updateParent={updateParent}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case ReservationSteps.CC_REVIEW:
        document.title =
          t("basePageTitle") + t("ReservationWizard.reviewCarClubPageTitle");
        return (
          <ReviewCarClubForm
            step={step}
            journeyUId={id}
            origJourneyUId={origid}
            isSharedAccessEnabled={isCCSharedAccessEnabled}
            updateParent={updateParent}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );

      case ReservationSteps.CC_CONFIRM:
        document.title =
          t("basePageTitle") +
          t("ReservationWizard.confirmationCarClubPageTitle");
        return (
          <ConfirmCarClubForm
            step={step}
            journeyUId={id}
            origJourneyUId={origid}
            isSharedAccessEnabled={isCCSharedAccessEnabled}
            updateParent={updateParent}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );

      case ReservationSteps.CC_DRIVER:
        document.title =
          t("basePageTitle") + t("ReservationWizard.driverDetailsCCTitle");
        return (
          <CarClubDetail
            step={step}
            journeyUId={id}
            carclubregisterhyperlink={
              productData.find(
                (i) => i.productUId.toUpperCase() === JourneyType.carClub
              )?.companyCarShareEnrollmentLink ?? ""
            }
            handleSelected={(driverId) => {
              carClubSelectedDriver = driverId;
            }}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case ReservationSteps.SV_REQUEST:
        document.title =
          t("basePageTitle") +
          t("ReservationWizard.reservationRequestPageTitle");
        return (
          <ReservationRequest
            step={step}
            journeyUId={id}
            journeyDetails={journeyProfile}
            svRequest={svRequest}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case ReservationSteps.SV_REVIEW:
        document.title =
          t("basePageTitle") + t("ReservationWizard.reviewPageTitle");
        return (
          <ReviewSpecialityRequest
            step={step}
            journeyUId={id}
            origJourneyUId={origid}
            updateParent={updateParent}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  }
  function getSteps() {
    let steps = Array();
    for (const [propertyKey, propertyValue] of Object.entries(
      ReservationSteps
    )) {
      if (!Number.isNaN(Number(propertyKey))) {
        continue;
      }
      if (currentSteps.has(+propertyValue)) {
        steps.push(propertyKey.toString());
      }
    }
    return steps;
  }
  function hideJourneyDetails(step) {
    const steps: string[] = getSteps();
    const stp: ReservationSteps = ReservationSteps[steps[step]];
    return (
      stp === ReservationSteps.REVIEW ||
      stp === ReservationSteps.CONFIRM ||
      stp === ReservationSteps.CC_REVIEW ||
      stp === ReservationSteps.CC_CONFIRM ||
      stp === ReservationSteps.CC_DRIVER ||
      stp === ReservationSteps.ADDITIONALINFO ||
      stp === ReservationSteps.RESERVATION ||
      stp === ReservationSteps.BBC_RESERVATION ||
      stp === ReservationSteps.VEHICLES ||
      stp === ReservationSteps.DETAILS ||
      stp === ReservationSteps.SV_REQUEST ||
      stp === ReservationSteps.SV_REVIEW ||
      stp === ReservationSteps.DR_DRIVERS ||
      stp === ReservationSteps.DR_VEHICLES ||
      stp === ReservationSteps.PAYMENT
    );
  }

  function getBackButtonLabel() {
    const steps: string[] = getSteps();
    const stp: ReservationSteps = ReservationSteps[steps[activeStep]];
    return stp === ReservationSteps.PRODUCTS
      ? t("JourneyDetails.btnStartAgain")
      : t("JourneyDetails.btnBack");
  }

  function getStepId(key) {
    let result = StepIds[key];
    return result;
  }
  function isLastStep() {
    return activeStep === currentSteps.size - 1;
  }
  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    await _sleep(1000);
    //alert(JSON.stringify(values, null, 2));
    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep()) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
    }
  }

  function processStepNavigation(step){
    if (step === 1) {
      if (!hasAdditionalFields) {
        history("/home");
        return;
      } else {
        console.log("MR AD fields transferred");
        setCurrentSteps(milageReimbWithAddFieldsSteps);
        setActiveStep(3);
      }
    }
    //Daily Rental
    else if (step === 2) {
      setCurrentSteps(
        companyInfo?.isDriverValidityActivated
          ? bbcDailyRentalSteps
          : dailyRentalSteps
      );
      setActiveStep(2);
    } else if (step === 3) {
      if (
        productData.find(
          (i) => i.productUId.toUpperCase() === JourneyType.carClub
        )?.isSharedAccessEnabled
      ) {
        setCurrentSteps(carClubSteps);
        setActiveStep(2);
      } else {
        setCurrentSteps(carClubStepsWithoutDriver);
        CheckCarClubVehicleAvailability(journeyId);
      }
    }
  }

  function _handleNext(step, addStep, enabled = false) {
    const steps: string[] = getSteps();
    let stp: ReservationSteps = ReservationSteps[steps[activeStep]];
    if (stp === ReservationSteps.PRODUCTS) {
      processStepNavigation(step);
    } else if (stp === ReservationSteps.RISKASSESSMENT) {
      UpdateProducts();
      setActiveStep(activeStep + 1);
    } else if (stp === ReservationSteps.CC_DRIVER) {
      CheckCarClubVehicleAvailability(journeyId);
    } else if (stp === ReservationSteps.DR_VEHICLES) {
      if (enabled && currentSteps.size == 7) {
        setCurrentSteps(dailyRentalCreditCardSteps);
        setActiveStep(5);
      } else if (enabled && currentSteps.size == 6) {
        setCurrentSteps(directDailyRentalCreditCardSteps);
        setActiveStep(4);
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (isLastStep()) {
    } else setActiveStep(activeStep + 1);
  }

  function _handleBack(
    step: number,
    startAgain: number,
    ccPwdFocused: boolean = false,
    backTwoSteps: boolean = false
  ) {
    setfocusCCPwd(ccPwdFocused);
    const steps: string[] = getSteps();
    let resType = qType ?? "";
    let stp: ReservationSteps = ReservationSteps[steps[activeStep]];
    switch (stp) {
      case ReservationSteps.RISKASSESSMENT:
        history("/home");
        break;
      case ReservationSteps.PRODUCTS:
        history("/home?id=" + qId + "&isStartAgain=1");
        break;
      case ReservationSteps.BBC_RESERVATION:
        if (mode === "3") {
          if (resType === "1" || resType === "2") history("/home");
          else history("/home?id=" + qId + "&isStartAgain=1");
        } else setActiveStep(activeStep - 1);
        break;
      case ReservationSteps.DR_DRIVERS:
        if (mode === "3") {
          if (resType === "1" || resType === "2") history("/home");
          else history("/home?id=" + qId + "&isStartAgain=1");
        } else setActiveStep(activeStep - 1);
        break;
      case ReservationSteps.CC_DRIVER || ReservationSteps.VEHICLES:
        setCurrentSteps(
          companyInfo?.isDriverValidityActivated
            ? bbcDailyRentalSteps
            : dailyRentalSteps
        );
        if (activeStep > 1) setActiveStep(activeStep - (backTwoSteps ? 2 : 1));
        break;
      case ReservationSteps.SV_REQUEST:
        history("/home?id=" + qId + "&isStartAgain=1");
        break;
      case ReservationSteps.PAYMENT:
        if (currentSteps.size == 8) {
          setCurrentSteps(dailyRentalSteps);
        } else if (currentSteps.size == 7) {
          setCurrentSteps(directDailyRentalSteps);
        }
        if (activeStep > 1) setActiveStep(activeStep - (backTwoSteps ? 2 : 1));
        break;
      default:
        if (activeStep > 1) setActiveStep(activeStep - (backTwoSteps ? 2 : 1));
        break;
    }
  }

  const CheckCarClubVehicleAvailability = async (journeyId) => {
    dispatch(clearAlertMessage());
    setLoading(true);
    let baseURL = `availability/vehicles/${journeyId}`;
    if (
      carClubSelectedDriver !== "" &&
      carClubSelectedDriver !== authService.getUserCarClubId()
    )
      baseURL = baseURL + "?primaryCarClubDriverId=" + carClubSelectedDriver;
    await axiosInstance
      .get(baseURL)
      .then((response) => {
        if (response.data.success) {
          //vehicles are available
          setLoading(false);
          console.log("carclub availability: ", response.data.data);
          const vehicles = {
            vehicles: response.data.data,
            errors: [],
            hasError: false,
          };
          dispatch(carClubVehicleAvailability(vehicles));
          setHideCCTile(false);
          setActiveStep(activeStep + 1);
        } else {
          //no available vehicle found
          const vehicles = {
            vehicles: undefined,
            errors: response.data.messages,
            hasError: true,
          };
          dispatch(carClubVehicleAvailability(vehicles));
          setLoading(false);
          setActiveStep(1);
          let error = response.data.messages;
          if (
            response.data.messages === "CarClubVehicleNotAvailableForDistance"
          ) {
            error = "Res.carClubVehicleNotAvailableForDistance";
            setHideCCTile(true);
            dispatch(showInfoAlertMessage(t(error)));
          } else {
            setHideCCTile(false);
            dispatch(showErrorAlertMessage(t(error)));
          }
          console.log("response.data", error);
          dispatch(broadcastAnnouncement(t(error)));
        }
      })
      .catch((error) => {
        setLoading(false);
        if (
          error.response &&
          error.response.status != null &&
          error.response.status === 400
        ) {
          dispatch(showErrorAlertMessage(error.message));
          dispatch(broadcastAnnouncement(error.message));
        } else {
          dispatch(showErrorAlertMessage(t("UnexpectedError")));
          dispatch(broadcastAnnouncement(t("UnexpectedError")));
          console.error("There is an error", error);
        }
      });
  };

  function UpdateProducts() {
    setLoading(true);
    axiosInstance
      .get("/journeyassessment/products/" + journeyId)
      .then((response) => {
        setProductData(response.data);
        setActiveStep(activeStep + 1);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setLoading(false);
        //appInsights.trackException({id : "ViewCC" , exception: error,  severityLevel: SeverityLevel.Error});
      });
  }

  const CreateSpeciality = async (id, additionalField) => {
    var svReservationRequest = (await CreateSpecialityRequest(
      id
    )) as SpecialityRequestState;
    var driver = authService.getDriverInfo();
    if (svReservationRequest.request && !svReservationRequest.hasError) {
      svReservationRequest.request.reservation.additionalInformation =
        additionalField;
      if (!isNullOrUndefined(driver)) {
        var drivers: DriverDeclaration[] = [];
        if (driver.primaryDriver) {
          let primary: DriverDeclaration = JSON.parse(
            JSON.stringify(driver.primaryDriver)
          );
          primary.isPrimaryDriver = true;
          drivers.push(primary);
        }
        driver.additionalDriver &&
          driver.additionalDriver.forEach((element) => {
            drivers.push(element);
          });
        svReservationRequest.request.reservation.drivers = drivers;
      }
      setSvRequest(svReservationRequest.request);
      dispatch(updateSpecialityRequest(svReservationRequest));
    } else dispatch(showErrorAlertMessage(svReservationRequest.errors));
  };

  const getWizardDetails = async (model) => {
    await axiosInstance
    .post("/reservationwizard/wizard", model)
    .then((response) => {
      setLoading(false);
      setProductData(response.data.data.products);
      const hasAddFieldStep = response.data.data.hasAdditionalInformation;
      const mrDriverAccordionToggle =
        response.data.data.mrDriverAccordionToggle;
      setHasAdditionalFields(!!(hasAddFieldStep | mrDriverAccordionToggle));
      setJourneyProfile(response.data.data.journeyDetails);
      setAdditionalFields(response.data.data.additionalInformation);
      setJourneyProfilerViaPoints(
        response.data.data.journeyDetails.journeyProfilerViaPoints
      );
      handleCarClubAutoVeraDetails(
        response.data.data.autoVeraDetails as CarClubAutoVeraDetails
      );
      dispatch(
        updateJourneyProfileDetails(response.data.data.journeyDetails)
      );
      const isRA = isRiskAssessmentSelectable(response.data.data.products);
      switch (qMode) {
        case "1":
          setCurrentSteps(
            hasAddFieldStep && isRA
              ? milageReimbWithAddFieldsSteps
              : milageReimbSteps
          );
          if (!isRA) setActiveStep(2);
          break;
        case "2":
          console.log("Setting Daily Rental");
          setCurrentSteps(
            companyInfo?.isDriverValidityActivated
              ? bbcDailyRentalSteps
              : dailyRentalSteps
          );
          break;
        case "3":
          setCurrentSteps(
            companyInfo?.isDriverValidityActivated
              ? bbcDirectDailyRentalSteps
              : directDailyRentalSteps
          );
          break;
        case "4": {
          CreateSpeciality(
            model.JourneyId,
            response.data.data.additionalInformation
          );
          setCurrentSteps(specialityRequestSteps);
          break;
        }
        default:
          setCurrentSteps(
            companyInfo?.isDriverValidityActivated
              ? bbcDailyRentalSteps
              : dailyRentalSteps
          );
          break;
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
      setLoading(false);
      //appInsights.trackException({id : "ViewCC" , exception: error,  severityLevel: SeverityLevel.Error});
    });
  };


  function GetWizardData() {
    setLoading(true);
    let model = {
      JourneyId: qId,
      OrigionalJourneyId: qOid,
      Mode: qMode,
    }; 
    getWizardDetails(model);
  }

  function isRiskAssessmentSelectable(products) {
    if (products && products.length > 0) {
      const riskProduct: any = products.filter(
        (p) =>
          p.productName === "MileageReimbursement" && p.isSelectable === true
      );
      return riskProduct && riskProduct.length > 0;
    }
    return false;
  }

  return (
    <Box boxShadow={3} p={3} bgcolor="background.paper">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>

      {loading ? (
        <div
          style={{
            textAlign: "center",
            width: "100%",
            marginTop: "10px",
            fontWeight: "bold",
          }}
        >
          <strong>{t("pleaseWait")}</strong>
        </div>
      ) : (
        <>
          <Stepper
            data-testid="stepperid"
            activeStep={activeStep}
            className={classes.stepper}
            orientation={isSmXs ? "vertical" : "horizontal"}
          >
            {getSteps().map((label, index) => {
              return (
                <Step key={label} id={getStepId(label)}>
                  <StepLabel
                    icon={
                      index < activeStep ? (
                        <CompletedIcon bgColor={"#006639"} />
                      ) : (
                        <StepIcon
                          id={index + 1}
                          bgColor={index <= activeStep ? "#006639" : "#707070"}
                        />
                      )
                    }
                    StepIconProps={{ classes: { root: classes.stepIcon } }}
                  >
                    {t("ReservationWizard.wizardSteps." + label)}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Divider />
          <Grid container>
            {!hideJourneyDetails(activeStep) && (
              <Grid item xs={12} md={3}>
                <JourneyDetailsForm
                  journeyUId={qId?.toString() || ""}
                  handleBack={_handleBack}
                  backBtnLabel={getBackButtonLabel()}
                  journeyDetails={journeyProfile}
                />
              </Grid>
            )}
            <Grid item xs={12} md={hideJourneyDetails(activeStep) ? 12 : 9}>
              {_renderStepContent(
                activeStep,
                journeyId,
                origJourneyId,
                _handleSubmit,
                _handleBack,
                _handleNext
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
