import moment, { localeData } from "moment-timezone";
import { authService } from "services/authService";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";
import "moment/locale/en-gb";

export enum currencyMapping {
  en = "GBP",
  de = "EUR",
  es = "EUR",
  fr = "EUR",
  ca = "CAD",
  us = "USD",
  za = "ZAR",
  no = "NOK",
  se = "SEK",
  dk = "DKK",
  pl = "PLN"
}

export enum countryLocaleMapping {
  FR = "fr-FR",
  DE = "de-DE",
  ES = "es-ES",
  GB = "en-GB",
  IE = "en-IE",
  CA = "ca-CA",
  US = "us-US",
}

export enum countryCurrencyMapping {
  FR = "EUR",
  DE = "EUR",
  ES = "EUR",
  GB = "GBP",
  IE = "EUR",
  CA = "CAD",
  US = "USD",
  ZA = "ZAR",
  NO = "NOK",
  SE = "SEK",
  DK = "DKK",
  PL = "PLN"
};

//mapping language code to 'moment -> locale' for MUI5 datepicker
export const datePickerLocaleMapping = {
  "de-DE": "de",
  "es-ES": "es",
  "fr-FR": "fr",
  "fr-CA": "fr-ca",
  "en-US": moment.locale("en"),
  "en-CA": "en-ca",
  "en-GB": "en-gb",
  undefined: "en-gb",
};

//mapping language code to 'moment -> weekdaysShort' for MUI5 datepicker
// !! Order in Array is important !!
export const datePickerWeekdayLocaleMapping = {
  "de-DE": ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
  "es-ES": ["lu", "ma", "mi", "ju", "vi", "sá", "do"],
  "fr-FR": ["lu", "ma", "me", "je", "ve", "sa", "di"],
  "fr-CA": ["di", "lu", "ma", "me", "je", "ve", "sa"],
  "en-US": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  "en-CA": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  "en-GB": ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
  undefined: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
};

//return Date object without time from short date string
//Assuming inputString is "2021-11-29"
export function getDateObjectFromShortString(inputString: string) {
  return new Date(inputString + "T00:00:00");
}

export function getLocaleDate(inputString: string, locale: string) {
  var dateString = new Date(Date.parse(inputString)).toLocaleString(locale, {
    hourCycle: "h23",
    //weekday: "long",
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12:
      locale === "en-US" || locale === "en-CA" || locale === "fr-CA"
        ? true
        : false,
  });

  dateString = dateString.replace(",", " ");

  if (locale === "en-US" || locale === "en-CA" || locale === "fr-CA") {
    return dateString
      .replace("p.m.", "PM")
      .replace("a.m.", "AM")
      .replace("p.m", "PM")
      .replace("a.m", "AM")
      .replace("am", "AM")
      .replace("pm", "PM");
  } else {
    return dateString;
  }
}

export function getLocaleShortDate(inputString: string, locale: string) {
  var dateString = new Date(Date.parse(inputString)).toLocaleString(locale, {
    hourCycle: "h23",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return dateString.replace(",", " ");
}

export function convertTimeZone(inputString: string, locale: string): string {
  //assume inputString is UK/UTC time "2021-11-29T11:00:00" and return same format but for other time zone
  var tzString;

  switch (locale) {
    case "de":
      tzString = "Europe/Berlin";
      break;
    default:
      return inputString;
  }

  const tzDateTime1 = moment(new Date(inputString))
    .tz(tzString)
    .format("YYYY-MM-DDTHH:mm:ss");
  var tzDateTime = new Date(tzDateTime1);

  // issue 40315 fixing: CC crashes on IE browser - Vehicle selection page DE
  //var tzDateTime = new Date(new Date(inputString.concat(".000Z")).toLocaleString("en-US", {timeZone: tzString}));

  return (
    tzDateTime.getFullYear() +
    "-" +
    pad(tzDateTime.getMonth() + 1) +
    "-" +
    pad(tzDateTime.getDate()) +
    "T" +
    pad(tzDateTime.getHours()) +
    ":" +
    pad(tzDateTime.getMinutes()) +
    ":" +
    pad(tzDateTime.getSeconds())
  );
}
function pad(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number;
}

export function getLocaleAmount(
  inputString: string,
  locale: string,
  useGrouping: boolean = false
) {
  return Number.parseFloat(inputString).toLocaleString(locale, {
    minimumFractionDigits: 2,
    useGrouping: useGrouping,
  });
}

export function convertKmToMiles(inputString: string, locale: string) {
  //inputString from Api like "3.52 km"
  var distanceString = inputString.replace(" km", "");
  var distance = Number.parseFloat(distanceString);
  if (locale.toString() === "en-GB" || locale.toString() === "en-US") {
    return getLocaleAmount((distance / 1.609).toFixed(2), locale) + " miles";
  } else {
    return getLocaleAmount(distanceString, locale) + " km";
  }
}

export function getCompanyLocaleAmount(
  inputString: string,
  companyCountry: string
) {
  var amt = new Intl.NumberFormat(countryLocaleMapping[companyCountry], {
    style: "currency",
    currency: countryCurrencyMapping[companyCountry],
    currencyDisplay: "code",
  }).format(Number.parseFloat(inputString));
  return amt;
}

export function getcompanyCountryCurrency(companyCountry: string) {
  return countryCurrencyMapping[companyCountry];
}

export function getLocaleCurrency(
  priceWithCurrency: string | undefined,
  locale: string,
  currency?: string
): string {
  var result = "";

  //====dealing with special cases:
  priceWithCurrency = priceWithCurrency?.replace(",", ".");

  var amount =
    priceWithCurrency !== undefined
      ? Number.parseFloat(
          priceWithCurrency.replace(/[^0-9.-]+/g, "")
        ).toString()
      : "";
  var existingCurrency =
    priceWithCurrency !== undefined
      ? priceWithCurrency.replace(/[^A-Za-z/ ]/g, "")
      : "";

  if (amount !== "") {
    var amt = Number.parseFloat(amount);
    result = new Intl.NumberFormat(
      locale,
      //localeMapping[locale],
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
      // { style: 'currency', currency: currency ?? currencyMapping[locale] }
    ).format(amt); // amount + " " + (currency ? (currency??"") : existingCurrency).trim();
  }
  return result + " " + currency;
}

export function formatfieldValueDateString(date) {
  var d = new Date(date);
  var month = "" + (d.getMonth() + 1);
  var day = "" + d.getDate();
  var year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("");
}

export function getIntlDateFormatForLocale(
  locale: string,
  options?: Intl.DateTimeFormatOptions
) {
  switch (locale) {
    case "en-GB":
      return "dd/MM/yyyy";
    case "fr-FR":
      return "dd/MM/yyyy";
    case "es-ES":
      return "dd/MM/yyyy";
    case "de-DE":
      return "dd.MM.yyyy";
    case "en-US":
      return "MM-dd-yyyy";
    case "en-CA":
      return "yyyy-MM-dd";
    case "fr-CA":
      return "yyyy-MM-dd";
    default:
      return "dd/MM/yyyy";
  }
}

export function convertToDate(dt: any) {
  if (dt === null || dt === "") return null;
  if (dt && typeof dt === "string" && dt.includes("-")) {
    let dateParts = dt.split("-");
    return new Date(+dateParts[0], Number(dateParts[1]) - 1, +dateParts[2]);
  }
  return moment(dt).format("YYYY-MM-DD");
}

export function getIntlDateSeparatorForLocale(locale: string) {
  switch (locale) {
    case "en-GB":
    case "fr-FR":
    case "es-ES":
      return "/";
    case "de-DE":
      return ".";
    case "en-US":
    case "en-CA":
    case "fr-CA":
      return "-";
    default:
      return "/";
  }
}
export function getDateMaskForLocale(locale?: string) {
  if (!locale) {
    locale = authService.getUserLocale();
  }
  switch (locale) {
    case "en-GB":
      return "99/99/9999";
    case "fr-FR":
      return "99/99/9999";
    case "es-ES":
      return "99/99/9999";
    case "de-DE":
      return "99.99.9999";
    case "en-US":
      return "99-99-9999";
    case "en-CA":
      return "9999-99-99";
    case "fr-CA":
      return "9999-99-99";
    default:
      return "";
  }
}

export function browsersLanugage() {
  let browsersLanguage: string = navigator.language;

  // en-*
  if (browsersLanguage === "en-GB" || browsersLanguage === "en") {
    return "en-GB";
  } else if (browsersLanguage === "en-US") {
    return "en-US";
  } else if (browsersLanguage === "en-CA") {
    return "en-CA";
  }
  // else if  (browsersLanguage.startsWith("en-")){
  //   return "en-GB";
  // }

  // fr-*
  else if (browsersLanguage === "fr-FR" || browsersLanguage === "fr") {
    return "fr-FR";
  } else if (browsersLanguage === "fr-CA") {
    return "fr-CA";
  }
  // else if  (browsersLanguage.startsWith("fr-")){
  //   return "fr-FR";
  // }

  // de-*
  else if (
    browsersLanguage === "de-DE" ||
    browsersLanguage === "de"
    // || browsersLanguage.startsWith("de-")
  ) {
    return "de-DE";
  }

  // es-*
  else if (
    browsersLanguage === "es-ES" ||
    browsersLanguage === "es"
    //  || browsersLanguage.startsWith("es-")
  ) {
    return "es-ES";
  }
  // all others
  else {
    return "en-GB";
  }
}
export function loginLabel(locale?: string) {
  if (!locale) {
    locale = browsersLanugage();
  }

  switch (locale) {
    case "en-GB":
    case "en-US":
    case "en-CA":
      return "Login";
    case "fr-FR":
    case "fr-CA":
      return "Connexion";
    case "es-ES":
      return "Inicio de sesión";
    case "de-DE":
      return "Anmeldung";
    default:
      return "Login";
  }
}
export function loginPageTitle() {
  switch (browsersLanugage()) {
    case "en-GB":
    case "en-US":
    case "en-CA":
      return "Enterprise Rent-A-Car - Login";
    case "fr-FR":
    case "fr-CA":
      return "Enterprise Rent-A-Car - Connexion";
    case "es-ES":
      return "Enterprise Rent-A-Car - Inicio de sesión";
    case "de-DE":
      return "Enterprise Rent-A-Car - Anmeldung";
    default:
      return "Enterprise Rent-A-Car - Login";
  }
}

export function to12HourFormat(locale: string, timeIn24: string) {
  if (locale === "en-US" || locale === "en-CA" || locale === "fr-CA") {
    let hoursMins = timeIn24.split(":");
    let hours = hoursMins[0];
    let mins = hoursMins[1];

    let hoursIn12 = ((Number(hours) + 11) % 12) + 1;

    return Number(hours) >= 12
      ? hoursIn12 + ":" + mins + " PM"
      : hoursIn12 + ":" + mins + " AM";
  } else {
    return timeIn24;
  }
}
